* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* color: #a6282e; */
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* padding-bottom: 100px; */
  /* min-height: 100vh; */
}

/* body:first-child {
  min-height: 100vh;
} */

/* ------------ General Styling ------------- */

/* header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
} */

h1 {
  /* margin: 10px 0; */
  color: #a6282e;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 10px 0;
  color: #303030;
  /* font-family: "Montserrat"; */
}

h3 {
  margin: 20px 0;
  color: #303030;
}

ul li {
  padding: 0.4rem;
  text-align: left;
}

.cstm-button {
  background-color: #a6282e;
  border: none;
  padding: 0.8rem;
  font-family: "Roboto";
  border-radius: 4px;
  color: white;
  /* width: fit-content; */
  min-width: 150px;
  cursor: pointer;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 250px;
  background-color: #c8c8c8;
  height: 1px;
  margin: auto;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a6282e;
  min-height: 50px;
  text-align: center;
  z-index: 10;
  margin-top: 20px;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
/* -------------- Home Component -------------- */

.span-alert {
  color: #a6282e;

  padding: 10px 0;
}

.select-dates {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0;
  align-items: center;
}

.rmdp-wrapper.rmdp-shadow {
  box-shadow: 0 0 0 white !important;
  /* width: 100% !important; */
}

.rmdp-wrapper {
  z-index: 1 !important;
}
.rmdp-calendar {
  /* width: 100% !important; */
  /* margin: 0 10% !important; */
}

.rmdp-calendar div {
  /* width: 100% !important; */
}

.rmdp-arrow {
  border: solid #a6282e !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: #a0a0a0 !important;
}

.rmdp-week-day {
  color: #a6282e !important;
}

.rmdp-day.rmdp-today span {
  background-color: #fff !important;
  color: black !important;
  border-radius: 50% !important;
  border: 1px solid #a6282e !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #a6282e !important;
}

/*https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices*/
@media (hover: hover) and (pointer: fine) {
  .rmdp-day.rmdp-today span:hover {
    border: none !important;
  }
  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: #c8c8c8 !important;
    color: #fff;
  }
  span.sd:hover {
    color: #fff !important;
  }
}

/* -------------- Roster Component -------------- */

.add-member-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 25px 0;
  align-items: center;
}

.add-member-text-group {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.add-member-field-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
}

.add-member-input {
  width: 320px;
  height: 50px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: #333; /* Change to appropriate text color */
  background: #fff;
  border: 1px solid #ccc; /* Change to appropriate border color */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Change to appropriate box-shadow values */
}

.add-member-field:hover,
.add-member-field:focus {
  border: 1px solid rgba(0, 0, 0, 0.87);
  outline: 0;
}

.selected-section {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 25px 0;
  align-items: center;
}

/* ---------- RosterTable Component ----------- */

.mui-table {
  min-width: 600px;
}

@media screen and (max-width: 599px) {
  .mui-table {
    min-width: 320px;
  }
}

/* ------------ Absences Component ------------- */
/* .absences-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.absences-list {
  display: flex;
  flex-direction: column;
}

.absences-list > * {
  display: flex;
  align-items: center;
  gap: 15px;
}

.link {
  text-decoration: none;
  color: #303030;
  font-size: 12px;
  margin-left: 20px;
}

p.link {
  cursor: pointer;
}

.absences {
  padding: 0.4rem;
  text-align: left;
}

.clear-all {
  margin: 3em 0;
}

/* ----------- Auth Components ----------- */
.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  margin: 0 auto;
  margin-top: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card h2 {
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.alert {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
.text-center {
  text-align: center;
}
.mt-2 {
  margin-top: 20px;
}
/* .w-50 {
  width: 50%;
} */

/* -------------- Nav Menu --------------  */
.nav-menu {
  position: sticky; /* Add position: sticky */
  top: 0; /* Stick to the top */
  background-color: white; /* Ensure background color is applied */
  z-index: 10; /* Ensure the header stays above other content */
  margin-bottom: 30px;
}

.container {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 60px;
  box-shadow: 0 4px 2px -2px lightgrey;
}

.nav-menu-left {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

.nav-menu-brand {
  color: black;
  font-size: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-menu-right,
.nav-list {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

#myLinks {
  display: none;
}

.icon-open {
  color: black;
}
.icon-open:hover {
  color: #a6282e;
  cursor: pointer;
}

.icon-close {
  color: black;
}
.icon-close:hover {
  color: #a6282e;
  cursor: pointer;
}

.nav-item {
  margin-left: 1rem;
  border-bottom: 2px solid transparent;
}

.nav-link,
.nav-logo {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.nav-link:hover,
.nav-logo:hover {
  border-bottom: 2px solid #a6282e;
}

.nav-link.active,
.nav-logo.active {
  border-bottom: 2px solid #a6282e;
}

.logout-btn {
  background: white;
  border: none;
  color: #a6282e;
  cursor: pointer;
}

.dropbtn {
  cursor: pointer;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  /* background-color: #f9f9f9; */
  background-color: #a6282e;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  /* float: none; */
  /* color: black; */
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: right;
}

.dropdown-content a:hover {
  /* background-color: darkgray; */
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.show-menu {
  right: 0;
}

.show-mobile {
  display: none;
}

.hide-mobile {
  display: block;
}

@media screen and (max-width: 768px) {
  .nav-list {
    position: fixed;
    top: 50px;
    right: 0;
    background-color: #a6282e;
    width: 40%;
    height: auto;
    padding: 2rem;
    transition: right 0.4s;
    align-items: end;
    box-shadow: 0 4px 2px -2px lightgrey;
  }

  .nav-list {
    flex-direction: column;
    column-gap: 2.5rem;
  }
  .nav-link {
    color: white;
  }

  .nav-link:hover {
    color: black;
  }

  .show-menu {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .icon-open {
    display: none;
  }

  .icon-close {
    display: none;
  }

  .nav-list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}

/* ------------- Copyright Component -------------- */

/* ---------------- Members Component ----------------- */
/* .member-table {
  border-collapse: collapse;
  margin-top: 5em;
}

.member-table td,
.member-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.member-table tbody tr td:nth-child(1) {
  text-align: center;
  width: 50px;
}

.member-table tbody tr td:not(:first-child) {
  width: 150px;
}

.member-table tr:hover {
  background-color: darkgray;
}

.member-table tr {
  text-align: left;
}

.member-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #a6282e;
  color: white;
} */
